import { initialState } from './state'

const SET_SPECS_ERROR = (state, error) => {
  state.specsError = error
}

const SET_SPECS_RESULT = (state, result) => {
  state.specsResult = result
}

const SET_SPECS_FORM_VALIDATION = (state, value) => {
  state.specsFormValidation = { ...state.specsFormValidation, ...value }
}

const SET_SPECS = (state, specs) => {
  state.specs = { ...state.specs, ...specs }
}

const SET_RADIATION_ERROR = (state, error) => {
  state.radiationError = error
}

const SET_TAX_TYPE = (state, taxType) => {
  state.taxType = taxType
}

const SET_INSTALLATION_TAX = (state, installationTax) => {
  state.installationTax = installationTax
}

const SET_OFFERS = (state, offers) => {
  state[state.activeOfferContext] = offers
}

const UPDATE_OFFER = (state, offer) => {
  state[state.activeOfferContext][state.selectedOffer] = offer
}

const SET_SELECTED_OFFER = (state, selectedOffer) => {
  state.selectedOffer = selectedOffer
}

const SET_OFFER_ERROR = (state, error) => {
  state.offerError = error
}

const SET_ACTIVE_OFFER_CONTEXT = (state, activeOfferContext) => {
  state.activeOfferContext = activeOfferContext
}

const RESET_STATE = (state) => {
  Object.assign(state, initialState)
}

const SET_DUE_DATE = (state, dueDate) => {
  state.dueDate = dueDate
}

const SET_FINANCING_CONDITIONS = (state, financingConditions) => {
  state.financingConditions = financingConditions
}

const SET_SELECTED_FINANCING_CONDITION = (
  state,
  selectedFinancingCondition
) => {
  state.selectedFinancingCondition = selectedFinancingCondition
}

const UPDATE_OFFER_FINANCING_DATA = (state, financingData) => {
  const offer = state[state.activeOfferContext][state.selectedOffer]
  state[state.activeOfferContext][state.selectedOffer] = {
    ...offer,
    financingData,
  }
}

const SET_ELECTRICITY_CONTRACT_ID = (state, contractId) => {
  state.electricityContractId = contractId
}

const SET_DOWNLOAD_INDIRECT_PDF_LOADING = (state, isLoading) => {
  state.loadingIndirectSolarOfferPdf = isLoading
}

const SHOW_DOWNLOAD_INDIRECT_ALERT = (state, show) => {
  state.showIndirectDownloadPdfAlert = show
}

const SET_DOWNLOAD_INDIRECT_PDF_ERROR = (state, hasError) => {
  state.errorDownloadIndirectSolarOfferPdf = hasError
}

const CLEAR_INDIRECT_OFFER_PDF = (state) => {
  state.loadingIndirectSolarOfferPdf = false
  state.errorDownloadIndirectSolarOfferPdf = false
  state.showIndirectDownloadPdfAlert = false
}

const SET_IS_VERIFIED = (state, isVerified) => {
  state.isVerified = isVerified
}

const SET_IS_OFFERABLE = (state, isOfferable) => {
  state.isOfferable = isOfferable
}

const SET_IS_OFFERABLE_ERROR = (state, isOfferableError) => {
  state.isOfferableError = isOfferableError
}

const CLEAR_IS_OFFERABLE = (state) => {
  state.isOfferable = null
  state.isOfferableError = false
}
const UPDATE_CUSTOM_SOLAR_OFFER = (state, simulatedOffer) => {
  state.simulatedOffer = simulatedOffer
}

const CLEAR_SIMULATED_OFFER = (state) => {
  state.simulatedOffer = {}
}

const SET_IS_SIMULATED_OFFER_LOADING = (state, isSimulatedOfferLoading) => {
  state.isSimulatedOfferLoading = isSimulatedOfferLoading
}

const SET_CLIENT_TYPE = (state, clientType) => {
  state.clientType = clientType
}

const SET_ENERGY_REFORM = (state, hasDoneEnergyReform) => {
  state.hasDoneEnergyReform = hasDoneEnergyReform
}

const SET_ASSETS_CATALOG = (state, assetsCatalog) => {
  state.assetsCatalog = assetsCatalog
}

const SET_ASSETS_CATALOG_LOADING = (state, isLoading) => {
  state.loadingAssetsCatalog = isLoading
}

const SET_ASSETS_CATALOG_ERROR = (state, hasError) => {
  state.errorAssetsCatalog = hasError
}

const SET_CONTRACT = (state, contract) => {
  state.contract = contract
}

const SET_CONTRACT_ERROR = (state, contractError) => {
  state.contractError = contractError
}

const SET_HIDE_SIMULATION_OFFER = (state, value) => {
  state.isSimulationOfferHidden = value
}

export default {
  SET_SPECS_ERROR,
  SET_SPECS_RESULT,
  SET_SPECS_FORM_VALIDATION,
  SET_SPECS,
  SET_RADIATION_ERROR,
  SET_TAX_TYPE,
  SET_INSTALLATION_TAX,
  SET_OFFERS,
  UPDATE_OFFER,
  SET_SELECTED_OFFER,
  SET_OFFER_ERROR,
  SET_ACTIVE_OFFER_CONTEXT,
  RESET_STATE,
  SET_DUE_DATE,
  SET_FINANCING_CONDITIONS,
  SET_SELECTED_FINANCING_CONDITION,
  UPDATE_OFFER_FINANCING_DATA,
  SET_ELECTRICITY_CONTRACT_ID,
  SET_DOWNLOAD_INDIRECT_PDF_LOADING,
  SHOW_DOWNLOAD_INDIRECT_ALERT,
  SET_DOWNLOAD_INDIRECT_PDF_ERROR,
  CLEAR_INDIRECT_OFFER_PDF,
  SET_IS_VERIFIED,
  SET_IS_OFFERABLE,
  SET_IS_OFFERABLE_ERROR,
  CLEAR_IS_OFFERABLE,
  UPDATE_CUSTOM_SOLAR_OFFER,
  CLEAR_SIMULATED_OFFER,
  SET_IS_SIMULATED_OFFER_LOADING,
  SET_CLIENT_TYPE,
  SET_ENERGY_REFORM,
  SET_ASSETS_CATALOG,
  SET_ASSETS_CATALOG_LOADING,
  SET_ASSETS_CATALOG_ERROR,
  SET_CONTRACT,
  SET_CONTRACT_ERROR,
  SET_HIDE_SIMULATION_OFFER,
}
